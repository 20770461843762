<template>
  <div class="qchat-messenger-form">
    <div v-if="isDirectSignIn" class="qcentered-item h-100">
      <div class="qchat-direct-sign qcentered-item">
        <div class="q-text-center">
          <div class="q-spinner-border q-spinner-border-sm" role="status"></div>
          <br />
          <p class="q-mt-1 mb-0">Signing as</p>
          <span class="qchat-direct-sign__email">{{ userEmail }}</span>
        </div>
      </div>
    </div>
    <div v-else class="qchat-form">
      <div>
        <label class="qchat-form__label q-mb-0">
          Name
          <span v-if="validation.name" class="qchat-form__error q-mb-1">{{
            validation.name
          }}</span>
        </label>
        <input
          v-model="form.name"
          type="text"
          name="Name"
          class="q-form-control q-mb-2 q-mt-1"
        />
        <label class="qchat-form__label q-mb-0">
          Email Address
          <span v-if="validation.email" class="qchat-form__error q-mb-1">{{
            validation.email
          }}</span>
        </label>
        <input
          v-model="form.email"
          type="email"
          name="Email"
          class="q-form-control q-mb-2 q-mt-1"
        />
        <label class="qchat-form__label q-mb-0">
          Phone Number
          <span v-if="validation.phone" class="qchat-form__error q-mb-1">{{
            validation.phone
          }}</span>
        </label>
        <div class="q-d-flex q-mt-1">
          <select
            v-model="form.countrycode"
            class="q-form-control q-mr-1 qchat-form__countrycode"
            label="dial_code"
            :style="formStyle"
          >
            <option
              v-for="country in countryCodeLists"
              :key="country.code"
              :value="country.dial_code"
            >
              {{ country.dial_code + " (" + country.name + ")" }}
            </option>
          </select>
          <input
            v-model="form.phone"
            type="text"
            name="Phone"
            class="q-form-control q-mb-3"
            @keyup.enter="onSubmit"
          />
        </div>
        <button
          class="q-btn q-w-100"
          @click="onSubmit"
          :style="styles.generalButton"
        >
          <span v-if="!loading" class="q-font-weight-bold">Start Chatting</span>
          <div
            v-else
            class="q-spinner-border q-spinner-border-sm"
            role="status"
          ></div>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      form: {
        name: "",
        email: "",
        countrycode: "+62",
        phone: ""
      },
      loading: false,
      userEmail: "",
      validation: {
        name: null,
        email: null,
        phone: null
      }
    };
  },
  computed: {
    ...mapState("messenger", ["countryCodeLists", "styles", "isDirectSignIn"]),
    formStyle() {
      let width;
      switch (this.form.countrycode.length) {
        case 0:
          width = "4.25rem";
          break;
        case 3:
          width = "4.25rem";
          break;
        case 4:
          width = "4.6rem";
          break;
        case 5:
          width = "4.9rem";
          break;
        case 6:
          width = "5.2rem";
          break;
      }
      return { width };
    }
  },
  beforeMount() {
    this.$store.dispatch("messenger/getCountryCode");
  },
  created() {
    const userData = JSON.parse(localStorage.getItem("qchat_user"));
    if (userData) this.userEmail = userData.email;
  },
  methods: {
    getRules(required, options) {
      return options ? required + "|" + options : "required";
    },
    async onSubmit() {
      this.validation = {
        name: "",
        email: "",
        phone: ""
      };

      await this.doValidation();

      if (
        !this.validation.name &&
        !this.validation.email &&
        !this.validation.phone
      ) {
        this.loading = true;
        this.$store
          .dispatch("messenger/registerWebchat", {
            name: this.form.name,
            email: this.form.email,
            phone_number: this.form.phone
              ? this.form.countrycode + this.form.phone
              : ""
          })
          .then(() => {
            this.showNotification(
              `Welcome, ${this.form.email}!`,
              "info-circle",
              5000
            );
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    doValidation() {
      if (!this.form.name) this.validation.name = "(Name cannot be empty)";
      if (!this.form.email) this.validation.email = "(Email cannot be empty)";
      if (!this.form.phone)
        this.validation.phone = "(Phone Number cannot be empty)";
      const phoneNumberValidation = this.phoneNumberValidation();
      if (!phoneNumberValidation)
        this.validation.phone = "(Invalid Phone Number format)";
      if (this.form.phone.length > 14)
        this.validation.phone = "(Reached maximum Phone Number limit: 14)";
      const emailValidator = this.emailValidator();
      if (!emailValidator) this.validation.email = "Invalid Email format";
    },
    phoneNumberValidation() {
      const numbers = /^[0-9]+$/;
      if (this.form.phone) {
        if (this.form.phone.match(numbers)) return true;
        else return false;
      } else return false;
    },
    emailValidator() {
      if (this.form.email) {
        // eslint-disable-next-line
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(this.form.email);
      } else return false;
    }
  }
};
</script>
